import { mapMutations } from 'vuex';

export default {
  // the solution to inject the function from app context
  inject: ['accountService', 'showAppDoc'],
  components: {},
  data() {
    return {
      /**
       * Show password in input or not
       */
      showPassword: false,
      /**
       * The login form elements
       */
      user: {
        email: '',
        password: '',
      },
      value: false,
      rememberUser: false,
      termsAccepted: false,
      /**
       * Should terms and conditions be displayed to the user. Applicable for generated but not registered users.
       */
      showTerms: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations(['setUICI']),
    init() {
      // detect email param forwarded from some other page like order confirmation
      const emailParam = this.$router.currentRoute.params.email;
      if (emailParam) {
        this.user.email = emailParam;
      }
    },
    async loginUser() {
      const result = await this.$validator.validateAll();

      if (result) {
        try {
          await this.accountService().doLogin({
            ...this.user,
            rememberUser: this.rememberUser,
            termsAccepted: this.termsAccepted,
          });
          // if there is requested url clear it and go there
          const requestedUrl = sessionStorage.getItem('requested-url');
          if (requestedUrl) {
            // clear session and go
            sessionStorage.removeItem('requested-url');
            this.$router.replace(requestedUrl);
          } else {
            this.$router.replace(this.accountService().homePageByAuth());
          }
        } catch (e) {
          // console.log(e.message);
          // the most of alerts are already handled by the interceptor, but this one requires additional flags on login page
          if (e.message === 'error.terms_not_accepted') {
            this.showTerms = true;
          }
          // rethrow error to be displayed by global handler
          throw e;
        }

        /* NOTE: TC CUSTOM: the forwarding to requested route is not implemented, should be taken from JHI, this is maybe
        inappropriate place */
        /*
          if (this.$store.state.requestedRoute) {
            this.$router.push(this.$store.state.requestedRoute.path);
          } else {
            this.$router.push('/');
          }
  */
      }
    },
  },
};
