import { mapGetters } from 'vuex';
import { detect } from 'detect-browser';
// import constants from '../common/constants';

const browser = detect();
const APP_URL_TPL_REGEX = /{(.+)}/;

// const { SERVER_URL } = constants;

/**
 * Detects that the browser is Safari on IOS.
 *
 * @returns {boolean} - Returns true if confirmed.
 */
function isIosSafari() {
  return browser && browser.name && browser.name.startsWith('ios');
}

/**
 * Detects that the browser is other than Safari on IOS.
 *
 * @returns {boolean} - Returns true if confirmed.
 */
function isIosOther() {
  if (browser) {
    const name = browser.name || '';
    const os = browser.os || '';
    if (!name.startsWith('ios') && os === 'iOS') {
      return true;
    }
  }
  return false;
}

/**
 * Evaluates the application launch mode: standalone (PWA) or not.
 *
 * @returns {boolean} - True of the app is launched in PWA mode.
 */
function isLaunchedAsStandalone() {
  return !!(navigator.standalone || matchMedia('(display-mode: standalone)').matches);
}
/**
 * Chrome support installation on any platform.
 *
 * @returns {boolean} - Returns if Chrome.
 */
function isChrome() {
  return browser && browser.name && browser.name.startsWith('chrome');
}

/**
 * New Edge support installation on any platform.
 *
 * @returns {boolean} - Returns if Chrome.
 */
function isNewEdge() {
  return browser && browser.name && browser.name.startsWith('edge-');
}

/**
 * Detects that the OS is iOS.
 */
function isIosOS() {
  const os = browser.os || '';
  return os === 'iOS';
}

/**
 * Detects that the OS is Android.
 */
function isAndroid() {
  const os = browser.os || '';
  return os === 'Android OS';
}

/**
 * Detects that the OS is for mobile device.
 */
function isMobileOS() {
  const os = browser.os || '';
  return ['Android OS', 'iOS', 'BlackBerry OS', 'Windows Mobile', 'Amazon OS'].indexOf(os) >= 0;
}

/**
 * Closes current window tab. Thus NOT ALLOWED IN BROWSERS as security measure.
 */
function closeCurrentBrowserTab() {
  window.close();
}

/**
 * Helper function to strip html characters form some string.
 * Used in cases when v-html or similar unescaped sensitive content is displayed and parts of user-entered text
 * require safe text.
 *
 * @param {string} html - The html to clean.
 * @returns {string} - The cleaned string.
 */
function stripHtml(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

/**
 * Generates breq filter for company based on company's initialize ebUrl.
 *
 * @param company
 * @param breqFilter
 * @param {boolean} skipConfirmation - Whether to use company's skip confirmation URL or not.
 */
function getBreqFilterUrl(company, breqFilter, skipConfirmation = false) {
  const url = skipConfirmation ? company.ebScUrl : company.ebUrl;
  if (company && url && breqFilter) {
    return `${url}?brFlt=${breqFilter.guid}`;
  }
  return '';
}

/**
 * The Mixin with system and platform related methods.
 */
export default {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          // PWA app update refresh variables
          refreshing: false,
          registration: null,
          updateExists: false,
          // NOTE: TC CUSTOM - signal that API is updated, and that app update dialog needs to be shown, but
          // the action would be a page refresh in that case to trigger the worker service update
          apiUpdated: false,
        };
      },
      created() {
        // support for CUSTOM PWA software updated event
        // Listen for our custom event from the SW registration
        // A note about the once option; setting this option to true allows the listener to be called only once
        // AND removes the listener once invoked.
        document.addEventListener('swUpdated', this.updateAvailable, { once: true });

        // Prevent multiple refreshes
        if (navigator.serviceWorker) {
          navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.refreshing) return;
            this.refreshing = true;
            // Here the actual reload of the page occurs
            window.location.reload();
          });
        }
      },
      methods: {
        /**
         * The PWA app update is available so this is the handler.
         * Store the SW registration so we can send it a message.
         * We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
         * to alert the user there is an update they need to refresh for.
         *
         * @param {CustomEvent} event - The custom event when app update is available.
         */
        updateAvailable(event) {
          console.log('pwa app update available...');
          this.registration = event.detail;
          this.updateExists = true;
        },
        /**
         * Triggered by axios interceptor - there is an API update, the user needs to click on OK in dialog.
         * NOTE: TC CUSTOM: initiates the service worker update by API version update detection.
         */
        apiUpdateAvailable() {
          console.log('API app update available...');
          // thi will also show update acknowledge dialog
          this.apiUpdated = true;
        },

        /**
         * Refreshes the application PWA code based on available update.
         * Called when the user accepts the update.
         */
        refreshApp() {
          if (this.apiUpdated) {
            // The following will unregister all service workers for this app, and after that reload the page
            if (navigator.serviceWorker) {
              console.log('Service worker registered -> Unregistering all service workers and reloading...');
              navigator.serviceWorker
                .getRegistrations()
                .then((registrations) => Promise.all(registrations.map((r) => r.unregister())))
                .then(() => window.location.reload());
            } else {
              console.log('No service worker registered -> Reloading all...');
              window.location.reload();
            }
          } else {
            this.updateExists = false;
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return;
            // Send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          }
        },

        /**
         * Helper to close the current browser tab.
         */
        closeCurrentBrowserTab,
        /**
         * Evaluates the path to app docs (terms, policy, ...) by name and language.
         *
         * @param {string} name - The name of the document - snake case.
         */
        appDoc(name) {
          // return `${SERVER_URL}api/app-docs/${name}/${this.$store.getters.currentLanguage}`;
          // NOTE: relocated to public
          return `/terms/${name}.${this.$store.getters.currentLanguage}.html`;
        },
        /**
         * Go to page with specified name.
         *
         * @param {string} name - The vue router page name.
         * @param {Object} options - The standard vue routing options when used with name.
         */
        goTo(name, options = {}) {
          // NOTE: in order to prevent vee validate issues for delayed validation, it is paused before routing.
          if (this.$validator) {
            this.$validator.pause();
          }
          this.$router.push({ name, ...options });
          // NOTE: for FULL reloading current route this could be a solution
          // this.$router.go(0);
        },
        /**
         * Custom go back method executed when go back button is clicked.
         * NOTE: please inject the 'accountService' to the component using this function. Otherwise it will not work.
         * Should be improved in future.
         */
        goBack() {
          // NOTE: in order to prevent vee validate issues for delayed validation, it is paused before routing.
          if (this.$validator) {
            this.$validator.pause();
          }
          // TC CUSTOM: in case that the user came from some specific page, it should go back based on home page resolution
          // All exceptions should be configured when needed
          if ([null, 'orderConfirmationPage'].indexOf(this.accountService().fromRoute.name) >= 0) {
            this.$router.push(this.accountService().homePageByAuth());
            return;
          }
          this.$router.go(-1);
        },
        /**
         * NOTE: taken from:
         * https://davidwalsh.name/javascript-debounce-function
         *
         * Returns a function, that, as long as it continues to be invoked, will not
         * be triggered. The function will be called after it stops being called for
         * N milliseconds. If `immediate` is passed, trigger the function on the
         * If `immediate` is passed, trigger the function on the
         * leading edge, instead of the trailing.
         *
         * @param {Function} func - The function to be called.
         * @param {number} wait - The number of milliseconds to wait with next execution.
         * @param {boolean} immediate - If true, execute the function once immediately.
         * @return {function(...[*]=)} - The debounced function.
         */
        debounceThat(func, wait = 500, immediate = true) {
          let timeout;
          return function (...args) {
            const context = this;
            const later = function () {
              timeout = null;
              if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
          };
        },

        /**
         * TODO: v.10.0 - ALL debounce examples can go out to helpers.
         * A helper to debounce the invocation of some function.
         * Taken from: https://programmingwithmosh.com/javascript/javascript-throttle-and-debounce-patterns/ .
         *
         * @param {Function} callback - The function to throttle.
         * @param {number} interval - The interval set in milliseconds.
         * @returns {function(...[*]=)} - The throttled function.
         */
        debounceIt(callback, interval = 1000) {
          let debounceTimeoutId;

          return function (...args) {
            clearTimeout(debounceTimeoutId);
            debounceTimeoutId = setTimeout(() => callback.apply(this, args), interval);
          };
        },

        /**
         * The helper to throttle the function invocation and prevent double invocation inside defined interval.
         *
         * Taken from: https://programmingwithmosh.com/javascript/javascript-throttle-and-debounce-patterns/ .
         *
         * @param {Function} callback - The function to throttle.
         * @param {number} interval - The interval set in milliseconds.
         * @returns {function(...[*]=)} - The throttled function.
         */
        throttleIt(callback, interval = 1000) {
          let enableCall = true;

          return function (...args) {
            if (!enableCall) return;

            enableCall = false;
            callback.apply(this, args);
            setTimeout(() => {
              enableCall = true;
            }, interval);
          };
        },
        /**
         * The wrapper to supported copy to clipboard function.
         *
         * @param {string} text  - The text to copy to the clipboard.
         * @returns {Promise<void>} - The promise.
         */
        copyToClipboard(text) {
          return navigator.clipboard.writeText(text);
        },

        /**
         * Evaluates the type of message key based on the key prefix.
         *
         * @param {string} msgKey - The message key.
         */
        getMessageType(msgKey) {
          if (msgKey) {
            return msgKey.startsWith('message.') ? 'message' : 'error';
          }
          // if not specified return error to provoke the detection of the issue
          return 'error';
        },
        stripHtml,
        getBreqFilterUrl,
      },
      computed: {
        ...mapGetters(['sysInfo', 'currentLanguage']),
        browser() {
          return browser;
        },
        isAndroid,
        isIosOS,
        isIosSafari,
        isChrome,
        isNewEdge,
        isIosOther,
        isMobileOS,
        isLaunchedAsStandalone,
        isPromoMessageVisible() {
          return this.$route.name === 'loginPage' || (this.$route.name || '').match(/orderConfirmation/);
        },
        /**
         * Evaluates the app info URL based on currently selected language and default language.
         */
        appInfoURL() {
          if (!this.sysInfo.infoURL) {
            return '';
          }
          if (!this.sysInfo.pfdlInfoURL && this.sysInfo.langKey === this.currentLanguage) {
            // in case of default language, do not include the template
            return this.sysInfo.infoURL.replace(APP_URL_TPL_REGEX, '');
          }
          const tplMatch = APP_URL_TPL_REGEX.exec(this.sysInfo.infoURL);
          if (tplMatch) {
            return this.sysInfo.infoURL.replace(tplMatch[0], tplMatch[1].replace('langKey', this.currentLanguage));
          }
          // no match, return full URL
          return this.sysInfo.infoURL;
        },

        /**
         * Evaluates client locale for use in client app, that might differ from the language defined in the model.
         * Eg for Serbian, the latin variant must be forced.
         */
        clientLocale() {
          const langKey = this.$store.getters.currentLanguage;
          if (langKey === 'sr') {
            return 'sr-Latn-RS';
          }
          return langKey;
        },

        // ================================
        // Company related stuff
        // ================================
        /**
         * Evaluates whether the company QMS is in TAKEOVER mode.
         * NOTE: needed that page has an instance with company and settings loaded.
         */
        isQmsTakeover() {
          return !!(this.company && this.company.qmsAdvanced && this.company.qmsResMngMode === 'TAKEOVER');
        },

        /**
         * The global flag (stored as a route param) used to force the display of app notif messages in UI.
         * This way, after changing route it is possible to control the display.
         */
        showAppMsgs() {
          return this.$route.params.showAppMsgs;
        },
      },
    });
  },
};
