export default class JhiAlertService {
  constructor(store) {
    this.store = store;
    // initialize alerts
    this.store.commit('initAlert');
    // initialize progress
    this.store.commit('initProgress');
  }

  /**
   * Shows the alert based on message key.
   *
   * @param {string} msgKey - The message key that will be translated based on pre-loaded bundle on client.
   * If not translatable, it will be displayed as is ?
   *
   * @param {Object?} msgParams - The message parameters that can be used for parametrized translation keys.
   */
  showAlert(msgKey, msgParams = undefined) {
    this.store.commit('setAlertMessage', { msg: msgKey, msgParams });
  }

  /**
   * Shows the alert based on message key and entire Error object with errorDetails returned in http response and collected in axios interceptor handler.
   *
   * @param {string} msgKey - The message key that will be translated based on pre-loaded bundle on client.
   * If not translatable, it will be displayed as is ?
   *
   * @param {Object?} errorDetails - The error details collected with error from http response.
   */
  showAlertWithErrDetails(msgKey, errorDetails = undefined) {
    let resolvedMsgKey = msgKey;
    // if error details is present, prepare alert message accordingly
    const msgParams = {};
    if (errorDetails) {
      if (msgKey.startsWith('error.msapi')) {
        // add all possible fields for translating this kind of errors
        msgParams.resources = (errorDetails.resources || []).map((res) => res.name).join(', ');
        msgParams.phoneNumber = errorDetails.company ? errorDetails.company.phoneNumber : '';
        msgParams.companyName = errorDetails.company ? errorDetails.company.name : '';

        if (this.store.getters.isUserPCK) {
          // use simpler msapi message for client user
          resolvedMsgKey = 'error.msapi.assist_client_users';
        }
      }
    }
    this.store.commit('setAlertMessage', { msg: resolvedMsgKey, msgParams });
  }

  /**
   * Clear the alert by resetting the one and only message.
   */
  clearAlert() {
    this.store.commit('setAlertMessage', { msg: '' });
  }

  /**
   * Show/hide progress bar.
   *
   * @param {boolean} loading - The flag should the progress bar be displayed or not.
   */
  showProgress(loading = true) {
    this.store.commit('setProgress', loading);
  }
}
