import axios from 'axios';

/**
 * The service for executing action on orders over external access keys.
 */
export default class EaOrderService {
  constructor({ store, router }) {
    this.store = store;
    this.router = router;
    // this.server = this.store.modules.server;
    // this.init();
  }

  /**
   * Execute external access order command.
   *
   * @param {string} cmd - The command to invoke on order over external access.
   * @param {string} aKey - The order's access token.
   * @returns {Promise<Object>} The 'promised' Order object.
   */
  async eaOrderCmd(cmd, aKey) {
    const res = await axios.post(`api/orders-cmd/?cmd=${cmd}`, { aKey });
    return res.data;
  }
}
