import VueRouter from 'vue-router';

import RouterView from '../cmp/router-view.vue';
import LoginPage from '../pages/account/login.vue';
// NOTE: this page is needed to intercept external access, determine environment and settings from server
// and forward to appropriate page
// It will have a specific mapping
import ExternalAccessPage from '../pages/external-access.vue';

// NOTE: lazy loading pages and components
const RegistrationPage = () => import('../pages/account/registration.vue');
const ContactFormPage = () => import('../pages/contact-form.vue');
const AppInfoPage = () => import('../pages/app-info.vue');
const ActivationResultPage = () => import('../pages/account/activation-result.vue');
const ResetPasswordInitPage = () => import('../pages/account/reset-password-init.vue');
const ResetPasswordFinishPage = () => import('../pages/account/reset-password-finish.vue');
const MsapiConsentPage = () => import('../pages/msapi-consent.vue');

const AccountManagePage = () => import('../pages/account/account-manage.vue');
const AccountPersonalInfo = () => import('../pages/account/account-personal-info.vue');
const AccountDeletePage = () => import('../pages/account/account-delete.vue');

const ClientLandingPage = () => import('../pages/client/client-landing.vue');
const ClientFavoritesPage = () => import('../pages/client/client-favorites.vue');
const ClientOrdersPage = () => import('../pages/client/client-orders.vue');
const ClientFavoritesAddPage = () => import('../pages/company/company-favorite-add.vue');
const ClientFavoriteInfoPage = () => import('../pages/company/company-favorite-info.vue');
const BookingRequestMultiPage = () => import('../pages/client/booking-request-multi.vue');
const OrderDetailsPage = () => import('../pages/client/order-details.vue');
const OrderConfirmationPage = () => import('../pages/client/order-confirmation.vue');

const EaOrderDetailsPage = () => import('../pages/client/ea-order-details.vue');

const AgendaPage = () => import('../pages/company/agenda.vue');
const CompanyScreensPage = () => import('../pages/company/company-screens.vue');
const QMSOperatingPostPage = () => import('../pages/company/qms-operating-post.vue');
const OrderListPage = () => import('../pages/company/order-list.vue');
const OrderValidationListPage = () => import('../pages/company/order-validation-list.vue');

const EmployeeHolidaysPage = () => import('../pages/employee-management/employee-holidays.vue');
const EmployeeHolidayEditPage = () => import('../pages/employee-management/employee-holiday-edit.vue');

const CompanyImportListPage = () => import('../pages/company-management/company-import-list.vue');
const CompanyEditPage = () => import('../pages/company-management/company-edit.vue');

const CompanyManagementPage = () => import('../pages/company-management/company-management.vue');
const CompanySettingsPage = () => import('../pages/company-management/company-settings.vue');
const CompanySettingsLocationPage = () => import('../pages/company-management/company-settings-location.vue');
const CompanySettingsInfoPage = () => import('../pages/company-management/company-settings-info.vue');
const CompanySettingsKioskPage = () => import('../pages/company-management/company-settings-kiosk.vue');
const CompanyHelpPage = () => import('../pages/company-management/company-help.vue');
const CompanyHolidaysPage = () => import('../pages/company-management/company-holidays.vue');
const CompanyHolidayEditPage = () => import('../pages/company-management/company-holiday-edit.vue');
const CompanyWHsPage = () => import('../pages/company-management/company-whs.vue');
const EmployeeWHsPage = () => import('../pages/employee-management/employee-whs.vue');
const CompanyServicesPage = () => import('../pages/company-management/company-services.vue');
const CompanySpacingsPage = () => import('../pages/company-management/company-spacings.vue');
const CompanyServiceEditPage = () => import('../pages/company-management/company-service-edit.vue');
const CompanySpacingEditPage = () => import('../pages/company-management/company-spacing-edit.vue');
const CompanyEmployeesPage = () => import('../pages/company-management/company-employees.vue');
const CompanyEmployeeEditPage = () => import('../pages/company-management/company-employee-edit.vue');
const EmployeeServicesPage = () => import('../pages/employee-management/employee-services.vue');
const CompanyDocEditPage = () => import('../pages/company-management/company-doc-edit.vue');
const CompanyDocsPage = () => import('../pages/company-management/company-docs.vue');
const CompanyNoteEditPage = () => import('../pages/company-management/company-note-edit.vue');
const CompanyNotesPage = () => import('../pages/company-management/company-notes.vue');
const CompanyLocationEditPage = () => import('../pages/company-management/company-location-edit.vue');
const CompanyLocationsPage = () => import('../pages/company-management/company-locations.vue');
const CompanyBreqFilterEditPage = () => import('../pages/company-management/company-breq-filter-edit.vue');
const CompanyBreqFiltersPage = () => import('../pages/company-management/company-breq-filters.vue');
const CompanyRfiFieldEditPage = () => import('../pages/company-management/company-rfi-field-edit.vue');
const CompanyRfiFieldsPage = () => import('../pages/company-management/company-rfi-fields.vue');
const CompanyCIPage = () => import('../pages/company-management/company-ci.vue');

const SystemSettingsPage = () => import('../pages/system-settings.vue');

const UserManagePage = () => import('../pages/user/user-management.vue');
const UserListPage = () => import('../pages/user/user-list.vue');
const UserEdit = () => import('../pages/user/user-edit.vue');
const UserPassword = () => import('../pages/user/user-password.vue');

const router = new VueRouter({
  // Customize scroll behavior, always to top because of Safari
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  // todo - v0.1.0 - try to enable history mode
  // mode: 'history',
  routes: [
    {
      /* the external access page as a dispatcher */
      // the routing params: ctx - context - a valid code for needed resource/functionality, e.g. b - booking request
      // status - the status context
      // the id of the primary entity that is providing resource for needed context
      // for a start, the set of supported contexts is quite limited
      path: '/e/:ctx/:id?/:name?/:simpleId?',
      name: 'ea',
      component: ExternalAccessPage,
    },
    {
      /* the login component is at the root */
      path: '/login',
      name: 'loginPage',
      component: LoginPage,
    },
    {
      /* the contact form */
      path: '/contact-form',
      name: 'contactFormPage',
      component: ContactFormPage,
      meta: {
        // this one will trigger the presence of back button in header
        backButton: true,
      },
    },
    {
      /* the app info */
      path: '/app-info',
      name: 'appInfoPage',
      component: AppInfoPage,
      meta: {
        // this one will trigger the presence of back button in header
        backButton: true,
      },
    },
    {
      /* the order confirmation, the request is sent, show instructions how to confirm order */
      path: '/o-conf-sent',
      name: 'orderConfirmationSentPage',
      component: OrderConfirmationPage,
      meta: {
        // the working mode - sent request
        mode: 'sent',
      },
    },
    {
      /* The confirmation request is about to be sent on page load and results displayed */
      path: '/o-conf',
      name: 'orderConfirmationPage',
      component: OrderConfirmationPage,
      meta: {
        // confirm the order
        mode: 'confirm',
      },
    },
    {
      // external access order details page, available to clients with appropriate token
      path: '/ea-o/:cmd',
      name: 'eaOrderDetailsPage',
      component: EaOrderDetailsPage,
    },

    /*
    {
      /!* the order confirmation RESULT page: same code, but different route *!/
      /!* The confirmation result or error is displayed *!/
      path: '/o-conf-res',
      name: 'orderConfirmationResultPage',
      component: OrderConfirmationPage,
      meta: {
        status: 'ok',
      },
    },
*/
    // account public pages
    {
      path: '/account/reset-password/init',
      name: 'resetPasswordInitPage',
      component: ResetPasswordInitPage,
      meta: {
        // this one will trigger the presence of back button in header
        backButton: true,
      },
    },
    {
      path: '/account/reset-password/finish',
      name: 'resetPasswordFinishPage',
      component: ResetPasswordFinishPage,
    },
    {
      path: '/account/register',
      component: RegistrationPage,
      name: 'registrationPage',
    },
    {
      path: '/account/activate',
      component: ActivationResultPage,
      name: 'activationPage',
    },
    {
      path: '/msapi/consent',
      component: MsapiConsentPage,
      name: 'msapiConsentPage',
    },
    {
      // the parent page for all tabbed pages
      path: '/profile',
      redirect: '/profile/personal-info',
      component: AccountManagePage,
      children: [
        {
          path: 'personal-info',
          name: 'accountPersonalInfoPage',
          component: AccountPersonalInfo,
          meta: {
            authorities: ['USER', 'EMPLOYEE', 'SA'],
          },
        },
        {
          path: 'delete',
          name: 'accountDeletePage',
          component: AccountDeletePage,
          meta: {
            authorities: ['USER'],
          },
        },
      ],
    },
    {
      path: '/home',
      name: 'clientLandingPage',
      component: ClientLandingPage,
      meta: {
        authorities: ['USER'],
      },
      children: [
        {
          path: '/home/favorites',
          name: 'clientFavoritesPage',
          component: ClientFavoritesPage,
          meta: {
            authorities: ['USER'],
          },
        },
        {
          path: '/home/orders',
          name: 'clientOrdersPage',
          component: ClientOrdersPage,
          meta: {
            authorities: ['USER'],
          },
        },
      ],
    },
    {
      // booking request create and edit pages, combined with 2 route mappings but only one real page
      // one of the most used routes has a shorter URL form:
      // - no orderId param - create
      // - orderId param - edit
      // - no orderId param, but with srcId query param - reuse
      path: '/b',
      name: 'bookingRequestPage',
      component: BookingRequestMultiPage,
      meta: {
        backButton: true,
        // hide the back button if the user is not auth
        hideBBForNoAccount: true,
        // NOTE; in order to allow external access, the allowed authorities must be ALL
        // authorities: ['EMPLOYEE', 'USER'],
      },
    },
    {
      // the booking edit mode, see above
      path: '/be/:orderId',
      name: 'bookingRequestEditPage',
      component: BookingRequestMultiPage,
      meta: {
        backButton: true,
        backButtonKey: 'g.button.back_and_cancel_edit',
        // NOTE; in order to allow external access, the allowed authorities must be ALL
        // authorities: ['EMPLOYEE', 'USER'],
      },
    },
    {
      // order details page, available to user and employee
      path: '/orders/:orderId',
      name: 'orderDetails',
      component: OrderDetailsPage,
      meta: {
        authorities: ['EMPLOYEE', 'KIOSK', 'USER'],
      },
    },
    {
      path: '/users',
      component: RouterView,
      meta: {
        authorities: ['SA'],
      },
      children: [
        {
          path: '',
          component: UserListPage,
          name: 'userListPage',
          meta: {
            authorities: ['SA'],
          },
        },
        {
          path: 'create',
          name: 'userCreatePage',
          component: UserEdit,
          meta: {
            authorities: ['SA'],
          },
        },
        {
          path: 'manage/:id',
          component: UserManagePage,
          children: [
            {
              path: 'edit',
              name: 'userEditPage',
              component: UserEdit,
              meta: {
                authorities: ['SA'],
              },
            },
            {
              path: 'password',
              name: 'userPassword',
              component: UserPassword,
              meta: {
                authorities: ['SA'],
              },
            },
          ],
        },
      ],
    },
    {
      path: '/agenda',
      component: AgendaPage,
      name: 'agendaPage',
      meta: {
        authorities: ['EMPLOYEE'],
      },
    },
    {
      path: '/screens',
      component: CompanyScreensPage,
      name: 'companyScreensPage',
      meta: {
        authorities: ['EMPLOYEE'],
      },
    },
    {
      path: '/qms-operating-post',
      component: QMSOperatingPostPage,
      name: 'qmsOperatingPostPage',
      meta: {
        authorities: ['EMPLOYEE'],
      },
    },
    {
      path: '/order-validation',
      component: OrderValidationListPage,
      name: 'orderValidationListPage',
      meta: {
        authorities: ['EMPLOYEE'],
        backButton: true,
      },
    },
    // all company orders, the list that can be filtered
    {
      path: '/orders',
      component: OrderListPage,
      name: 'orderListPage',
      meta: {
        authorities: ['EMPLOYEE'],
      },
    },
    {
      path: '/company',
      component: RouterView,
      children: [
        {
          path: 'favorite/add',
          component: ClientFavoritesAddPage,
          name: 'clientFavoriteAddPage',
          meta: {
            authorities: ['USER'],
            backButton: true,
          },
        },
        {
          path: 'favorite/info',
          component: ClientFavoriteInfoPage,
          name: 'clientFavoriteInfoPage',
          meta: {
            authorities: ['USER'],
            backButton: true,
          },
        },
      ],
    },
    {
      path: '/system',
      component: SystemSettingsPage,
      name: 'systemSettingsPage',
      meta: {
        authorities: ['SA'],
      },
    },
    {
      path: '/companies-import',
      name: 'companyImportList',
      component: CompanyImportListPage,
      meta: {
        authorities: ['SA'],
      },
    },
    {
      path: '/companies/:id/edit',
      name: 'companyEdit',
      component: CompanyEditPage,
      meta: {
        authorities: ['SA'],
      },
    },
    {
      path: '/company-management',
      redirect: '/company-management/settings-location',
      component: CompanyManagementPage,
      meta: {
        authorities: ['CA'],
      },
      children: [
        {
          path: '/company-management/settings',
          name: 'companySettings',
          component: CompanySettingsPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/settings-location',
          name: 'companySettingsLocation',
          component: CompanySettingsLocationPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/settings-info',
          name: 'companySettingsInfo',
          component: CompanySettingsInfoPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/ci',
          name: 'companyCI',
          component: CompanyCIPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/settings-kiosk',
          name: 'companySettingsKiosk',
          component: CompanySettingsKioskPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/help',
          name: 'companyHelp',
          component: CompanyHelpPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          // NOTE: beforeEnter is removed because not working with promised next resolve
          path: `/company-management/holidays`,
          name: 'companyHolidays',
          component: CompanyHolidaysPage,
          meta: {
            authorities: ['CA'],
          },
        },
        // create and edit the holiday
        {
          path: '/company-management/holidays/create',
          name: 'companyHolidayCreate',
          component: CompanyHolidayEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/holidays/:holidayId/edit',
          name: 'companyHolidayEdit',
          component: CompanyHolidayEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        // manage company working hours
        {
          path: '/company-management/whs',
          name: 'companyWHs',
          component: CompanyWHsPage,
          meta: {
            authorities: ['CA'],
          },
        },
        // manage company services
        {
          path: '/company-management/services',
          name: 'companyServices',
          component: CompanyServicesPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/services/create',
          name: 'companyServiceCreate',
          component: CompanyServiceEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/services/:serviceId/edit',
          name: 'companyServiceEdit',
          component: CompanyServiceEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        // manage company service spacings
        {
          path: '/company-management/spacings',
          name: 'companySpacings',
          component: CompanySpacingsPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/spacings/create',
          name: 'companySpacingCreate',
          component: CompanySpacingEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/spacings/:serviceId/edit',
          name: 'companySpacingEdit',
          component: CompanySpacingEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        // manage company employees
        {
          path: '/company-management/employees',
          name: 'companyEmployees',
          component: CompanyEmployeesPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/employees/create',
          name: 'companyEmployeeCreate',
          component: CompanyEmployeeEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/employees/:employeeId/edit',
          name: 'companyEmployeeEdit',
          component: CompanyEmployeeEditPage,
          meta: {
            authorities: ['CA'],
          },
        },

        // manage employee working hours
        // NOTE: employee has no permission to manage own whs
        {
          path: '/company-management/employees/:employeeId/whs',
          name: 'employeeWHs',
          component: EmployeeWHsPage,
          meta: {
            authorities: ['CA'],
          },
        },
        // NOTE: employee has no permission to manage own assigned services
        {
          path: '/company-management/employees/:employeeId?/services',
          name: 'employeeServices',
          component: EmployeeServicesPage,
          meta: {
            authorities: ['CA'],
          },
        },
        // manage company documents
        {
          path: '/company-management/docs',
          name: 'companyCDocs',
          component: CompanyDocsPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/docs/create',
          name: 'companyCDocCreate',
          component: CompanyDocEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/docs/:docId/edit',
          name: 'companyCDocEdit',
          component: CompanyDocEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        // manage company notes
        {
          path: '/company-management/notes',
          name: 'companyNotes',
          component: CompanyNotesPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/notes/create',
          name: 'companyNoteCreate',
          component: CompanyNoteEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/notes/:noteId/edit',
          name: 'companyNoteEdit',
          component: CompanyNoteEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        // manage company locations
        {
          path: '/company-management/locations',
          name: 'companyLocations',
          component: CompanyLocationsPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/locations/create',
          name: 'companyLocationCreate',
          component: CompanyLocationEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/locations/:locationId/edit',
          name: 'companyLocationEdit',
          component: CompanyLocationEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        // manage company breq filters
        {
          path: '/company-management/breq-filters',
          name: 'companyBreqFilters',
          component: CompanyBreqFiltersPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/breq-filters/create',
          name: 'companyBreqFilterCreate',
          component: CompanyBreqFilterEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/breq-filters/:breqFilterId/edit',
          name: 'companyBreqFilterEdit',
          component: CompanyBreqFilterEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        // manage company RFI fields
        {
          path: '/company-management/rfi-fields',
          name: 'companyRfiFields',
          component: CompanyRfiFieldsPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/rfi-fields/create',
          name: 'companyRfiFieldCreate',
          component: CompanyRfiFieldEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
        {
          path: '/company-management/rfi-fields/:rfiFieldId/edit',
          name: 'companyRfiFieldEdit',
          component: CompanyRfiFieldEditPage,
          meta: {
            authorities: ['CA'],
          },
        },
      ],
    },
    {
      path: '/employee-management/:employeeId/holidays',
      name: 'employeeHolidays',
      component: EmployeeHolidaysPage,
      meta: {
        authorities: ['CA', 'EMPLOYEE'],
      },
    },
    // create and edit the holiday
    {
      path: '/employee-management/:employeeId/holidays/create',
      name: 'employeeHolidayCreate',
      component: EmployeeHolidayEditPage,
      meta: {
        authorities: ['CA', 'EMPLOYEE'],
        backButton: true,
      },
    },
    {
      path: '/employee-management/:employeeId/holidays/:holidayId/edit',
      name: 'employeeHolidayEdit',
      component: EmployeeHolidayEditPage,
      meta: {
        authorities: ['CA', 'EMPLOYEE'],
        backButton: true,
      },
    },
  ],
});

export default router;
