import axios from 'axios';

// import cookieUtils from '../common/customCookieUtils';
import constants from '../common/constants';

const { /* COOKIE_NAME, */ SERVER_URL, AUTH_TOKEN_NAME } = constants;

/**
 * The name of response custom header for REST API version.
 * @type {string}
 */
const API_HEADER_VERSION = 'x-api-version';

/**
 * The part of the url used to identify response from system info REST API endpoint.
 * @type {string}
 */
const API_SYS_INFO_URL_PART = '/api/system/info';

let appServices;

const TIMEOUT = 1000000;

// NOTE: TC CUSTOM: - this is the draft setup implementation for more complex progress bar - with data amount
/*
function onUploadProgress(ev) {
  console.log(ev);
  // do your thing here
}

function onDownloadProgress(ev) {
  console.log(ev);
  // do your thing here
}
*/

/**
 * Handle differences in client app version in the store and updated version info in API response.
 *
 * @param {Object} res - The response object.
 */
function checkAPIVersionUpdate(res) {
  // check client and server versions in all cases except when API is used to load the sysInfo
  if (res.config.url.indexOf(API_SYS_INFO_URL_PART) === -1) {
    const clientAppVersion = appServices.systemService.appVersion;
    const apiVersion = res.headers[API_HEADER_VERSION];
    // console.log('CLIENT VERSION -> API VERSION check...', clientAppVersion, apiVersion);
    if (clientAppVersion && apiVersion && clientAppVersion !== apiVersion) {
      console.log('API VERSION UPDATE DETECTED, client -> API!', clientAppVersion, ' -> ', apiVersion);

      // NOTE: TC CUSTOM: This logic is needed to initiate the display of OK dialog for user before reloading page.
      if (window.vueGlobalInstance) {
        console.log('Initiating app update through sw unregister...');

        window.vueGlobalInstance.$children[0].apiUpdateAvailable();
      } else {
        console.log('Forcing app reload...');

        // Here the actual reload of the page occurs - BRUTE FORCE - if vue instance is missing etc...
        window.location.reload();
      }
    }
  }
}

/**
 * Do something before request is sent - configure request.
 *
 * @param {Object} config - The axios configuration to modify.
 * @returns {Object} - The modified configuration.
 */
const onRequestSuccess = (config) => {
  // NOTE: The JHI way of storing credentials is now implemented. The commented code is for the old way:
  const token = localStorage.getItem(AUTH_TOKEN_NAME) || sessionStorage.getItem(AUTH_TOKEN_NAME);
  // The old way with cookie
  // const token = cookieUtils.getCookie(COOKIE_NAME);
  if (token) {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.timeout = TIMEOUT;
  config.url = `${SERVER_URL}${config.url}`;

  // NOTE: TC CUSTOM: - the basic setup for common UPLOAD progress bar:
  /*
  config.onUploadProgress = config.onUploadProgress || onUploadProgress;
  config.onDownloadProgress = config.onDownloadProgress || onDownloadProgress;
*/

  // basic progress bar - visible or not
  appServices.alertService.showProgress(true);

  return config;
};
const setupAxiosInterceptors = (services) => {
  appServices = services;

  const onResponseSuccess = (res) => {
    // first check that versions are matching
    checkAPIVersionUpdate(res);

    // hide progress
    appServices.alertService.showProgress(false);

    // show alert only if message is present in the response
    if (res.headers['message-key']) {
      appServices.alertService.showAlert(res.headers['message-key']);
    }
    // returning the complete response, no need to kick out anything right now
    return res;
  };

  /**
   * Handler for axios status errors.
   * NOTE: right now this handler is used to react in some particular cases, but error handling is delagated to client app error
   * handler and window error handler.
   *
   * @param {Function} err - The callback function for handling response status errors.
   * @returns {Promise<Object>} - The promise with custom error that will be delegated to error handlers.
   */
  const onResponseError = (err) => {
    // hide progress
    appServices.alertService.showProgress(false);

    let msgKey = 'error.no_client_request';

    if (err.response) {
      const status = err.status || err.response.status;
      // show error for some statuses if present
      msgKey = err.response.headers['message-key'];

      // NOTE: statuses 500 and 400 delegated to client app error handler

      // handle specific forbidden and not ath cases
      if (status === 403 || status === 401) {
        /* NOTE: the on unauthenticated handler logic is relocated here for better overview
        onUnauthenticated({
          status,
          msgKey,
        });
        */
        const alreadyAuth = appServices.accountService.authenticated;
        console.log(status, ' - Unauthorized!', 'Authenticated:', alreadyAuth);
        // in case that message key indicates the just deleted user, perform logout (the message will be shown by error handler)
        if (status === 401 && alreadyAuth) {
          // msgKey = 'error.account.deactivated';
          appServices.accountService.doLogout();
        }
      }
    } else if (err.request) {
      msgKey = 'error.no_connection_to_server';
    }

    // TC CUSTOM: always reject error in order to handle it in services, components or in the global error handler.
    // NOTE: TC CUSTOM - introduction of CUSTOM ERROR with error details, but NOT USED YET
    const customErr = new Error(msgKey);
    customErr.errorDetails = err.response && err.response.data ? err.response.data.errorDetails : {};
    return Promise.reject(customErr);
    // throw err;
  };

  if (axios.interceptors) {
    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(onResponseSuccess, onResponseError);
  }
};

export { onRequestSuccess, setupAxiosInterceptors };
