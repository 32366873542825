import axios from 'axios';
import qs from 'query-string';

/**
 * The service for managing users.
 */
export default class UserManageService {
  constructor({ store, router }) {
    this.store = store;
    this.router = router;
  }

  /**
   * Loads users (optionally with some query).
   *
   *
   * @param {Object} options - The options for retrieving the company.
   * @param {string} options.filter - The filter used to for all possible filtering.
   * @param {Array<string>} options.filter.sb - The array of 'sort by' fields used for sorting.
   * @param {string} options.filter.so - The array of 'sort order' fields associated with each specified 'sort by' field.
   * @param {string} options.filter.sflt - The value of 'scope filter'.
   */
  async retrieve(
    options = {
      filter: {
        sb: [],
        so: [],
        sflt: undefined,
      },
    },
  ) {
    const res = await axios.get(`api/users?${qs.stringify(options.filter, { arrayFormat: 'bracket' })}`);
    return res.data;
  }

  /**
   * Loads user by specified serial id.
   *
   * @param {number} id - The user serial id.
   * @returns {Promise<Object>} - The promise with object of retrieved user.
   */
  async retrieveUser(id) {
    const res = await axios.get(`api/users/${id}`);
    return res.data;
  }

  async create(formDTO) {
    const res = await axios.post(`api/users`, formDTO);
    return res.data;
  }

  async update(formDTO) {
    const res = await axios.put(`api/users/${formDTO.id}`, formDTO);
    return res.data;
  }

  async updatePassword(formDTO) {
    const res = await axios.put(`api/users/${formDTO.id}/password`, formDTO);
    return res.data;
  }

  async remove(id) {
    const res = await axios.delete(`api/users/${id}`);
    return res.data;
  }
}
