import axios from 'axios';
import queryString from 'query-string';

/**
 * The client service to support needed information about the company.
 */
export default class CompanyService {
  constructor({ store, router, appNotificationsService }) {
    this.store = store;
    this.router = router;
    // this.server = this.store.modules.server;
    // this.init();
    // We need notifications service here for some logic
    this.appNotificationsService = appNotificationsService;
  }

  /**
   * Loads company with employees and other non-sensitive data for display purposes.
   *
   * @param {number} companyId - The company serial id.
   * @param {Object} options - The options for retrieving the company.
   * @param {string} options.filter - The filter used to for all possible filtering.
   * @param {string} options.filter.sflt - The scope filter used to for applying scope filter on ORM.
   * @param {string} options.filter.brFlt - The booking request filter that groups possible services and resources.
   * @returns {Promise<Object>} - The promise with object of retrieved employee.
   */
  async retrieveCompany(
    companyId,
    options = {
      filter: {
        sflt: undefined,
        brFlt: undefined,
      },
    },
  ) {
    const res = await axios.get(
      `api/companies/${companyId}?${queryString.stringify(options.filter, { arrayFormat: 'bracket' })}`,
    );
    return res.data;
  }

  /**
   * Retrieves the orders of the company of the currently signed in Employee.
   * Currently applied filters are for validation process, but other kind of filtering should be supported as well.
   *
   * @param {Object} options - The options for retrieving orders.
   * @param {Object} options.filter - The object with key-value pairs of applied filters.
   * @returns {Promise<Array<Object>>} - The promised array of found orders.
   */
  async retrieveCompanyOrders(options) {
    const res = await axios.get(
      `api/companies/${this.store.getters.getCompanyId}/orders?${queryString.stringify(options.filter, {
        arrayFormat: 'bracket',
      })}`,
    );
    return res.data;
  }

  /**
   * Updates the validation status of the order.
   *
   * @param {string} newStatus - The valid enumerated validation status.
   * @param {number} orderId - The serial id of the order.
   * @returns {Promise<number>} - The promise with the number of updated orders.
   */
  async updateOrderVldStatus(newStatus, orderId) {
    const res = await axios.patch(`api/companies/${this.store.getters.getCompanyId}/orders/${orderId}`, {
      vldStatus: newStatus,
    });
    return res.data;
  }

  /**
   * Updates the QMS status of the order.
   *
   * @param {string} newStatus - The valid enumerated QMS status.
   * @param {number} orderId - The serial id of the order.
   * @returns {Promise<number>} - The promise with the number of updated employee bookings of the Order.
   */
  async updateOrderQMSStatus(newStatus, orderId) {
    const res = await axios.patch(`api/companies/${this.store.getters.getCompanyId}/orders/${orderId}`, {
      qmsStatus: newStatus,
      // NOTE: the socket id might be needed for unique requester identification when broadcasting messages
      socketId: this.appNotificationsService.socketId,
    });
    return res.data;
  }

  /**
   * Execute the specified QMS command.
   *
   * @param {string} command - The command to execute.
   * @param {Object} bookingsGroupId - The bookings group identifier.
   * @param {Array<number>} bookingsGroupId.resourceIds - The array of booking group resource ids used to identify the group with order id.
   * @param {number} bookingsGroupId.orderId - The serial id of the order.
   * @param {Object} optionalData - The optional payload data.
   * @param {number} optionalData.targetResId - The target resource serial id.
   * @param {Array<number>} optionalData.sid - The serial id of services of the order to assign to target resource.
   * @returns {Promise<number>} - The promise with the result of QMS command.
   */
  async execQMSCommand(command, { resourceIds, orderId }, { targetResId, sid } = {}) {
    const res = await axios.post(`api/companies/${this.store.getters.getCompanyId}/qms-cmd`, {
      cmd: command,
      resourceIds,
      orderId,
      targetResId,
      sid,
      // NOTE: the socket id might be needed for unique requester identification when broadcasting messages
      socketId: this.appNotificationsService.socketId,
    });
    return res.data;
  }

  /**
   * Request support from current employee user.
   *
   * @param {string} phoneNumber - The phone number specified in form.
   * @returns {Promise<void>} - The promise.
   */
  async supportRequest(phoneNumber) {
    const res = await axios.post(`api/companies/${this.store.getters.getCompanyId}/support-request`, { phoneNumber });
    return res.data;
  }

  /**
   * Retrieves the clients of the company of the currently signed in Employee.
   *
   * @param {Object} options - The options for retrieving clients.
   * @param {Object} options.filter - The object with key-value pairs of applied filters.
   * @returns {Promise<Array<Object>>} - The promised array of found clients.
   */
  async retrieveCompanyClients(options) {
    const res = await axios.get(
      `api/companies/${this.store.getters.getCompanyId}/clients?${queryString.stringify(options.filter, {
        arrayFormat: 'bracket',
      })}`,
    );
    return res.data;
  }

  /**
   * Retrieves the RFI fields for company that are needed for booking.
   * All general fields will be loaded and in addition service RFI fields for specified services.
   *
   * NOTE: the service is using public-info endpoint to get RFI data.
   * NOTE: the RFI fields will be returned in the order defined by domain logic.
   *
   * @param {number} companyId - The company serial id.
   * @param {Object} filter - The filter object.
   * @param {number[]} filter.sids - The serial ids of service RFI fields to include in result in addition to general company RFI fields.
   * @param {number} filter.email - The email of the client user which answers are needed with RFI fields. NOTE: for privacy reasons,
   * the usage if this of param is restricted and ignored by API in some cases. Check REST API for details.
   * @returns {Promise<Array<Object>>} - The promised array of free needed RFI fields.
   */
  async retrieveCompanyRFI(companyId, filter) {
    const res = await axios.get(`api/i/${companyId}/rfi?${queryString.stringify(filter, { arrayFormat: 'bracket' })}`);
    return res.data;
  }
}
