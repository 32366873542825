import dayjs from 'dayjs';
import io from 'socket.io-client';
import customFormatters from '../common/customFormatters';
import constants from '../common/constants';
// import cookieUtils from '../common/customCookieUtils';
// const { DATE_FORMAT_API } = constants;

const { SERVER_URL /* COOKIE_NAME */, AUTH_TOKEN_NAME } = constants;
// const { getCookie } = cookieUtils;

/**
 * The custom configuration of notification events per route.
 */
const PER_ROUTE_EVENTS_CONFIG = Object.freeze({
  qmsOperatingPostPage: {
    events: ['booking.created', 'booking.confirmed', 'booking.cancelled', 'booking.updated', 'qms.event', 'res.event'],
    /**
     * Show the message and perform other handling ONLY if the event has significance for signed in user.
     */
    onlyIfAffected: true,
  },
  // The following is an example for Agenda page, e.g. allowing only affected events.
  agendaPage: {
    onlyIfAffected: true,
  },
});

/**
 * The client service for handling application notifications to client (domain events)
 */
export default class AppNotificationsService {
  constructor({ store, router, alertService, translationService }) {
    console.log('APP NOTIFICATION SERVICE INSTANCE CREATED');
    this.store = store;
    this.router = router;
    // this.server = this.store.modules.server;
    // this.init();
    // the instances of needed services
    this.alertService = alertService;
    this.translationService = translationService;
    this.i18n = translationService.i18n;
    // the active socket, on instance it is null
    this.socket = null;
  }

  /**
   * Evaluates the configuration based on the route.
   *
   * @param {string} eventName - The unique event name.
   * @returns { {registered: boolean, onlyIfAffected: boolean} } - The route config.
   */
  routeConfig(eventName) {
    const perRouteCfg = PER_ROUTE_EVENTS_CONFIG[this.currentRoute];
    // If no custom configuration, then the events for the route are enabled
    if (!perRouteCfg) {
      return { registered: true, onlyIfAffected: false };
    }
    // if the list of events is not explicitly specified - it will be registered for ALL
    return {
      registered: perRouteCfg.events === undefined || perRouteCfg.events.indexOf(eventName) >= 0,
      onlyIfAffected: perRouteCfg.onlyIfAffected,
    };
  }

  /**
   * Weather the event should be handled based on route config and event payload.
   *
   * @param {boolean} onlyIfAffected - The flag with same meaning as in the route config.
   * @param {Array<number>} resIds - The array of serial ids of resources affected by the event.
   * @param {(number|null)?} resIdToMatch - The resource to match explicitly for allowing handler. If not specified the resource is matched
   * by the signed in user. If null or zero, then ALL resources would be matched.
   */
  handleEventById(onlyIfAffected, resIds, resIdToMatch = undefined) {
    if (onlyIfAffected) {
      let resId = resIdToMatch;
      if (resId === null || resId === 0) {
        return true;
      }
      if (!resId) {
        // res not specified, try with signed in user
        if (this.store.getters.isEmployee) {
          resId = this.store.getters.account.employeeId;
        }
      }
      return resIds.length && resIds.indexOf(resId) >= 0;
    }
    // by default, always display the message
    return true;
  }

  /**
   * Weather the event should be handled based on the time of event entity and specified time.
   *
   * @param {string|null} dateToMatch - The date to match (usually specified by current filter in date only format).
   * @param {Object} eventTimes - The time properties of the notification event's related entity. The 'prev' times are
   * the previous values for some range and they are optional and used in case of edit for example.
   */
  handleEventByDate(
    dateToMatch = undefined,
    { days = 1, tz, startTime, endTime, startTimePrev = undefined, endTimePrev = undefined },
  ) {
    const daysToAdd = days - 1;

    // IMPORTANT NOTE: If the conversion to timezones is used for date/time calculations and additionally for comparing dates,
    // the involved times should be converted to the utc or local. Otherwise the calculation will not work on client systems with tz diff than the used one.
    // always match within a range, if end time is not specified the day range would be matched
    // For display purposes it is different story and in that case we cannot obviously convert to utc.
    const rngStartDJS = dayjs(startTime)
      // NOTE: we need the end of the range in tz
      .tz(tz)
      .startOf('date')
      .utc();
    const rngEndDJS = dayjs(endTime || startTime)
      .tz(tz)
      .endOf('date')
      .utc();

    // if there are previous values for range, build range for matching that too
    const rngStartPrevDJS = startTimePrev ? dayjs(startTimePrev).tz(tz).startOf('date').utc() : undefined;

    const rngEndPrevDJS = startTimePrev
      ? dayjs(startTimePrev || endTimePrev)
          .tz(tz)
          .endOf('date')
          .utc()
      : undefined;

    // today's events and optional addition of calendar days are always checked
    // const todayDJS = dayjs().tz(tz);
    const todayStartDJS = dayjs().tz(tz).startOf('date').utc();

    const todayEndDJS = dayjs()
      .tz(tz)
      // if date to match is not specified, then it is today that is selected in the timeline, so add days in that case
      .add(dateToMatch ? 0 : daysToAdd, 'days')
      .endOf('date')
      .utc();

    // is today?
    let handleIt = todayStartDJS.isSameOrBefore(rngStartDJS) && todayEndDJS.isSameOrAfter(rngEndDJS);

    if (rngStartPrevDJS && rngEndPrevDJS) {
      handleIt =
        handleIt || (todayStartDJS.isSameOrBefore(rngStartPrevDJS) && todayEndDJS.isSameOrAfter(rngEndPrevDJS));
    }

    if (dateToMatch) {
      // (StartA <= EndB) and (EndA >= StartB)

      // in addition check that explicit date to match is
      // NOTE: in case of formatted date, we need to PARSE it within timezone!
      const dateToMatchStartDJS = dayjs.tz(dateToMatch, tz).startOf('date').utc();

      const dateToMatchEndDJS = dayjs.tz(dateToMatch, tz).add(daysToAdd, 'days').endOf('date').utc();

      // console.log('date to match', dateToMatch, dateToMatchStartDJS.toISOString(), dateToMatchEndDJS.toISOString());
      // console.log('rng', rngStartDJS.toISOString(), rngEndDJS.toISOString());

      handleIt =
        handleIt || (dateToMatchStartDJS.isSameOrBefore(rngStartDJS) && dateToMatchEndDJS.isSameOrAfter(rngEndDJS));

      if (rngStartPrevDJS && rngEndPrevDJS) {
        handleIt =
          handleIt ||
          (dateToMatchStartDJS.isSameOrBefore(rngStartPrevDJS) && dateToMatchEndDJS.isSameOrAfter(rngEndPrevDJS));
      }
    }

    return handleIt;
  }

  /**
   * Connects to socket server and returns the instance of the socket.
   *
   * @param {Function} cb - The callback function that can be executed after the alert is displayed.
   * @param {Object} options - The options.
   * @param {Object} options.company - The company in cases when notifications are used for company events (now all of the events are for company).
   * @param {(number|null)?} options.resIdToMatch - The serial id of the resource to match with event resource in order to handle event.
   * If null, the handler will activate for any resource.
   * @param {(string|null)} options.dateToMatch - The date to match in date only ISO string format. If specified and the date of the event related entity matches it, the event will be handled.
   * If null the event would be handled for today's date and if not specified, the event would be handled for any date.
   * @param {number} options.days - The days range to match. By default the range of 1 day is matched. However, in case of
   * calendar when several days are displayed at once, the match for refresh must take in account the whole range.
   * @param {Array<number>} options.orderIdsToMatch - The array of involved order identities to be matched, like on agenda the list of all orders.
   */
  connect(
    cb,
    options = {
      company: undefined,
      resIdToMatch: undefined,
      dateToMatch: undefined,
      days: 1,
      orderIdsToMatch: [],
    },
  ) {
    const { company, resIdToMatch, dateToMatch, days, orderIdsToMatch } = options;
    // in case of existing instance, disconnect it first
    this.disconnect();
    // start socket listening
    this.socket = io(SERVER_URL, {
      path: '/websocket',
      transports: ['websocket'],
      // NOTE: configure transport options when needed
      transportOptions: {},
      // withCredentials: false, // apparently not needed at all
      // The option to pass authentication
      auth: {
        // NOTE: still using cookies to tore the auth token, this will be changed in the future
        // token: getCookie(COOKIE_NAME),
        // The new way with browser store
        token: localStorage.getItem(AUTH_TOKEN_NAME) || sessionStorage.getItem(AUTH_TOKEN_NAME),
      },
    });

    console.log('SOCKET CONNECTED', resIdToMatch, days, orderIdsToMatch ? orderIdsToMatch.length : null);

    const tz = company ? company.city.tz : undefined;

    let cfg = this.routeConfig('booking.created');

    if (cfg.registered) {
      this.socket.on('booking.created', (bookingNotificationDTO) => {
        // NOTE: do not allow such logs on production!
        // console.log(
        //   this.handleEventById(cfg.onlyIfAffected, bookingNotificationDTO.resIds, resIdToMatch),
        //   this.handleEventByDate(dateToMatch, { days, tz, startTime: bookingNotificationDTO.time }),
        // );
        if (
          this.handleEventById(cfg.onlyIfAffected, bookingNotificationDTO.resIds, resIdToMatch) &&
          this.handleEventByDate(dateToMatch, { days, tz, startTime: bookingNotificationDTO.time })
        ) {
          // show alert with message key and params
          this.alertService.showAlert('message.notification.booking_created', {
            employeeName: bookingNotificationDTO.employeeNames,
            time: customFormatters.fmtDateTimeHuman(
              bookingNotificationDTO.time,
              this.store.getters.currentLanguage,
              bookingNotificationDTO.company.city.tz,
            ),
          });
          cb();
        } else if (
          bookingNotificationDTO.vldStatus === 'TO_REVIEW' &&
          this.currentRoute === 'agendaPage' &&
          // right now only for 'any' filter to reduce the amount of notifications...
          !resIdToMatch
        ) {
          // explicit handling of vldStatus order event if caused by booking created event and only when 'any' resources filter is selected
          // show alert with message key and params
          this.alertService.showAlert(`message.notification.order.updated.vld_status`);
          cb();
        }
      });
    }

    cfg = this.routeConfig('booking.updated');

    if (cfg.registered) {
      this.socket.on('booking.updated', (bookingNotificationDTO) => {
        if (
          this.handleEventById(cfg.onlyIfAffected, bookingNotificationDTO.resIds, resIdToMatch) &&
          this.handleEventByDate(dateToMatch, {
            days,
            tz,
            startTime: bookingNotificationDTO.time,
            startTimePrev: bookingNotificationDTO.timePrev,
          })
        ) {
          // show alert with message key and params
          this.alertService.showAlert('message.notification.booking_updated', {
            employeeName: bookingNotificationDTO.employeeNames,
            time: customFormatters.fmtDateTimeHuman(
              bookingNotificationDTO.time,
              this.store.getters.currentLanguage,
              bookingNotificationDTO.company.city.tz,
            ),
          });
          // console.log('message.notification.booking_updated', bookingNotificationDTO);
          cb();
        }
      });
    }

    cfg = this.routeConfig('booking.confirmed');

    if (cfg.registered) {
      this.socket.on('booking.confirmed', (bookingNotificationDTO) => {
        if (
          this.handleEventById(cfg.onlyIfAffected, bookingNotificationDTO.resIds, resIdToMatch) &&
          this.handleEventByDate(dateToMatch, { days, tz, startTime: bookingNotificationDTO.time })
        ) {
          // show alert with message key and params
          this.alertService.showAlert('message.notification.booking_confirmed', {
            employeeName: bookingNotificationDTO.employeeNames,
            time: customFormatters.fmtDateTimeHuman(
              bookingNotificationDTO.time,
              this.store.getters.currentLanguage,
              bookingNotificationDTO.company.city.tz,
            ),
          });
          // console.log('message.notification.booking_confirmed', bookingNotificationDTO);
          cb();
        } else if (
          bookingNotificationDTO.vldStatus === 'TO_REVIEW' &&
          this.currentRoute === 'agendaPage' &&
          // right now only for 'any' filter to reduce the amount of notifications...
          !resIdToMatch
        ) {
          // explicit handling of vldStatus order event if caused by booking created event and only when 'any' resources filter is selected
          // show alert with message key and params
          this.alertService.showAlert(`message.notification.order.updated.vld_status`);
          cb();
        }
      });
    }

    cfg = this.routeConfig('booking.cancelled');

    if (cfg.registered) {
      this.socket.on('booking.cancelled', (bookingNotificationDTO) => {
        if (
          this.handleEventById(cfg.onlyIfAffected, bookingNotificationDTO.resIds, resIdToMatch) &&
          this.handleEventByDate(dateToMatch, { days, tz, startTime: bookingNotificationDTO.time })
        ) {
          // show alert with message key and params
          this.alertService.showAlert('message.notification.booking_cancelled', {
            employeeName: bookingNotificationDTO.employeeNames,
            time: customFormatters.fmtDateTimeHuman(
              bookingNotificationDTO.time,
              this.store.getters.currentLanguage,
              bookingNotificationDTO.company.city.tz,
            ),
          });
          // console.log('message.notification.booking_created', bookingNotificationDTO);
          cb();
        }
      });
    }

    cfg = this.routeConfig('booking.creator_updated_with_employee_delete');

    if (cfg.registered) {
      this.socket.on('booking.creator_updated_with_employee_delete', (dto) => {
        // show alert with message key and params
        this.alertService.showAlert('message.notification.booking_creator_updated_with_resource_delete', {
          employeeName: dto.employee.name,
        });
        // console.log('message.notification.booking_creator_updated_with_resource_delete');
        cb();
      });
    }

    // if account is deleted and it is client with pending bookings, one global notification message is deleted
    cfg = this.routeConfig('booking.cancelled_with_account_delete');

    if (cfg.registered) {
      this.socket.on('booking.cancelled_with_account_delete', () => {
        this.alertService.showAlert('message.notification.pending_bookings_cancelled_with_account_delete');
        // console.log('message.notification.pending_bookings_cancelled_with_account_delete', notificationDTO);
        cb();
      });
    }

    // if expired orders are deleted, display notification and refresh
    cfg = this.routeConfig('orders.expired');

    if (cfg.registered) {
      this.socket.on('orders.expired', () => {
        this.alertService.showAlert('message.notification.orders_cancelled');
        // console.log('message.notification.orders_cancelled', notificationDTO);
        cb();
      });
    }

    // resource holidays
    cfg = this.routeConfig('holiday.event');

    if (cfg.registered) {
      this.socket.on('holiday.event', (notificationDTO) => {
        if (
          this.handleEventById(cfg.onlyIfAffected, notificationDTO.resIds, resIdToMatch) &&
          // match the complete time span of the holiday
          this.handleEventByDate(dateToMatch, {
            days,
            tz,
            startTime: notificationDTO.dateFrom,
            endTime: notificationDTO.dateTo,
            startTimePrev: notificationDTO.dateFromPrev,
            endTimePrev: notificationDTO.dateToPrev,
          })
        ) {
          const fmtFunctionToUse = notificationDTO.allDay
            ? customFormatters.fmtDateHuman
            : customFormatters.fmtDateTimeHuman;
          // show alert with message key and params
          this.alertService.showAlert(`message.notification.resource_holiday.${notificationDTO.eventName}`, {
            employeeName: notificationDTO.employeeName,
            dateFrom: fmtFunctionToUse(
              notificationDTO.dateFrom,
              this.store.getters.currentLanguage,
              notificationDTO.tz,
            ),
            dateTo: fmtFunctionToUse(notificationDTO.dateTo, this.store.getters.currentLanguage, notificationDTO.tz),
          });
          cb();
        }
      });
    }

    // company holidays, they are applicable for all resources, no filter by resource
    cfg = this.routeConfig('company_holiday.event');

    if (cfg.registered) {
      this.socket.on('company_holiday.event', (notificationDTO) => {
        if (
          // match the complete time span of the holiday
          this.handleEventByDate(dateToMatch, {
            days,
            tz,
            startTime: notificationDTO.dateFrom,
            endTime: notificationDTO.dateTo,
          })
        ) {
          const fmtFunctionToUse = notificationDTO.allDay
            ? customFormatters.fmtDateHuman
            : customFormatters.fmtDateTimeHuman;
          // show alert with message key and params
          this.alertService.showAlert(`message.notification.company_holiday.${notificationDTO.eventName}`, {
            dateFrom: fmtFunctionToUse(
              notificationDTO.dateFrom,
              this.store.getters.currentLanguage,
              notificationDTO.tz,
            ),
            dateTo: fmtFunctionToUse(notificationDTO.dateTo, this.store.getters.currentLanguage, notificationDTO.tz),
          });
          cb();
        }
      });
    }

    // orders, like vld status and other order fields
    cfg = this.routeConfig('order.event');

    if (cfg.registered) {
      this.socket.on('order.event', (notificationDTO) => {
        // show alert with message key and params
        this.alertService.showAlert(`message.notification.order.${notificationDTO.eventName}`);
        cb();
      });
    }

    cfg = this.routeConfig('qms.event');

    // QMS, like status and other QMS future events
    if (cfg.registered) {
      this.socket.on('qms.event', (notificationDTO) => {
        if (
          // optionally it would be possible to do the matching by displayed orders (this might be even better than matching by resource and time)
          // there is prepared structure affectedOrderIds
          // or by resource / date
          this.handleEventById(cfg.onlyIfAffected, notificationDTO.resIds, resIdToMatch) &&
          this.handleEventByDate(dateToMatch, { days, tz, startTime: notificationDTO.time })
        ) {
          // DO NOT show this alert to the logged in employee on the same socket?
          if (
            notificationDTO.employeeId === this.store.getters.getEmployeeId &&
            notificationDTO.socketId === this.socketId
          ) {
            return;
          }
          this.alertService.showAlert(`message.notification.qms.${notificationDTO.eventName}`);
          // NOTE: passing notification DTO to callback
          cb(notificationDTO);
        }
      });
    }

    cfg = this.routeConfig('res.event');

    // Resources related events
    if (cfg.registered) {
      this.socket.on('res.event', (notificationDTO) => {
        if (this.handleEventById(cfg.onlyIfAffected, notificationDTO.resIds, resIdToMatch)) {
          // DO NOT show this alert to the logged in employee on the same socket?
          if (
            notificationDTO.resId === this.store.getters.getEmployeeId &&
            notificationDTO.socketId === this.socketId
          ) {
            return;
          }
          this.alertService.showAlert(`message.notification.res.${notificationDTO.eventName}`, {
            resName: notificationDTO.resName,
          });
          cb();
        }
      });
    }

    return this.socket;
  }

  /**
   * Disconnects the active socket connection. Can be used when the page which used socket services is abandoned.
   */
  disconnect() {
    if (this.socket) {
      console.log('SOCKET DISCONNECTED');
      this.socket.close();
    }
  }

  /**
   * Returns the unique socket id if socket connection is established.
   */
  get socketId() {
    return this.socket ? this.socket.id : null;
  }

  get currentRoute() {
    return this.router.currentRoute.name;
  }
}
