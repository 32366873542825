import axios from 'axios';

/**
 * The service for handling system related stuff like system settings.
 */
export default class SystemService {
  constructor(store) {
    this.store = store;
    // this.init();
  }

  /**
   * Init the settings as a FIRST data loading from server because other services might depend on it.
   */
  async init() {
    return this.retrieveSysInfo().then((settings) => {
      this.store.commit('sysInfo', settings);
    });
  }

  /**
   * Retrieves ONLY the basic settings needed for UI proper functionality.
   */
  async retrieveSysInfo() {
    const res = await axios.get('api/system/info');
    return res.data;
  }

  /**
   * Retrieves ALL settings to manage. API available to SA.
   */
  async retrieveSettings() {
    const res = await axios.get('api/system/settings');
    return res.data;
  }

  /**
   * Updates settings.
   *
   * @param {Object} formDTO - The form with settings fields to persist. API available to SA.
   */
  async updateSettings(formDTO) {
    const res = await axios.put('api/system/settings', formDTO);
    // in order to refresh sys info fields, re-init the sys info
    this.init();
    return res.data;
  }

  /**
   * Updates SMTP cred settings ONLY.
   *
   * @param {Object} formDTO - The form with settings fields to persist. API available to SA.
   */
  async updateSmtpCrdSettings(formDTO) {
    const res = await axios.patch('api/system/settings/smtp-crd', formDTO);
    return res.data;
  }

  /**
   * Retrieve masked SMTP credentials.
   */
  async retrieveMaskedSmtpCrd() {
    const res = await axios.get('api/system/settings/smtp-crd');
    return res.data;
  }

  /**
   * Send contact form over the system.
   *
   * @param {Object} formDTO - The contact form.
   * @returns {Promise<void>} - The promise of the sent request.
   */
  async requestContact(formDTO) {
    await axios.post('api/contact-form', formDTO);
  }

  /**
   * The getter for app version from the store.
   *
   * @returns {string} The app version string, eg 'vX.Y.Z'.
   */
  get appVersion() {
    const { sysInfo } = this.store.getters;
    if (sysInfo && sysInfo.versionInfo) {
      const vInfo = sysInfo.versionInfo;
      return `v${vInfo.major}.${vInfo.minor}.${vInfo.patch}`;
    }
    return undefined;
  }
}
