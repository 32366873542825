import axios from 'axios';
import dayjs from 'dayjs';

import pickerUtils from '../common/pickerUtils';

/**
 * Prepares Holiday form for saving.
 *
 * @param {Object} formDTO - The DTO for managing holidays.
 * @param {string} tz - The standard timezone string.
 */
const prepareHolidayFormForSave = (
  formDTO = {
    id: undefined,
    employeeId: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    timeFrom: undefined,
    timeTo: undefined,
    allDay: false,
    remark: '',
    category: 'BREAK',
  },
  tz,
) => {
  // prepare data for API
  const employeeId = formDTO.employeeId || this.store.getters.getEmployeeId;
  const { dateFrom, dateTo, timeFrom, timeTo, ...dto } = formDTO;

  let dateFromM = dayjs.tz(dateFrom, pickerUtils.DATE_PICKER_INT_FMT, tz);
  let dateToM = dayjs.tz(dateTo, pickerUtils.DATE_PICKER_INT_FMT, tz);

  // if not all day, append hours and minutes
  if (!dto.allDay) {
    const timeFromM = dayjs.tz(timeFrom, pickerUtils.TIME_PICKER_INT_FMT, tz);
    const timeToM = dayjs.tz(timeTo, pickerUtils.TIME_PICKER_INT_FMT, tz);
    dateFromM = dateFromM.hour(timeFromM.hour()).minute(timeFromM.minute());
    dateToM = dateToM.hour(timeToM.hour()).minute(timeToM.minute());
  }

  dto.dateFrom = dateFromM.toISOString();
  dto.dateTo = dateToM.toISOString();
  dto.employeeId = employeeId;

  return dto;
};

/**
 * The service for managing employees.
 */
export default class EmployeeManageService {
  constructor({ store, router, appNotificationsService }) {
    this.store = store;
    this.router = router;
    // this.server = this.store.modules.server;
    // this.init();
    this.appNotificationsService = appNotificationsService;
  }

  /**
   * Prepares Holiday form for edit.
   *
   * @param {Object} holidayDTO - The DTO for saving holiday data.
   * @param {string} tz - The standard timezone string.
   */
  prepareHolidayFormForEdit(
    holidayDTO = {
      id: undefined,
      employeeId: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      allDay: false,
      remark: '',
      category: 'BREAK',
    },
    tz,
  ) {
    // prepare from based on data received from API
    const { dateFrom, dateTo, ...dto } = holidayDTO;

    const dateFromM = dayjs(dateFrom).tz(tz);
    const dateToM = dayjs(dateTo).tz(tz);

    return {
      ...dto,
      timeFrom: dateFromM.format(pickerUtils.TIME_PICKER_INT_FMT),
      timeTo: dateToM.format(pickerUtils.TIME_PICKER_INT_FMT),
      dateFrom: dateFromM.format(pickerUtils.DATE_PICKER_INT_FMT),
      dateTo: dateToM.format(pickerUtils.DATE_PICKER_INT_FMT),
    };
  }

  /**
   * Loads employee by specified serial id.
   * Only employee of the company can be loaded. It is NOT possible to load employee of other companies from the API.
   * NOTE: It is possible to get the info about soft-deleted resource (for now).
   *
   * @param {number} employeeId - The employee serial id.
   * @returns {Promise<Object>} - The promise with object of retrieved employee.
   */
  async retrieveEmployee(employeeId) {
    const res = await axios.get(`api/employees/${employeeId}`);
    return res.data;
  }

  /**
   *
   * @param {Object} filter - The composed filter for the list.
   * @param {number} filter.employeeId - The employee id
   * @param {string} filter.dateFilterVal - The value of date filter: ACTUAL, PAST
   * @returns {Promise<[]>} The promise with holidays array.
   */
  async retrieveHolidays(filter) {
    const res = await axios.get(`api/employees/${filter.employeeId}/holidays?dateFilterVal=${filter.dateFilterVal}`);
    return res.data;
  }

  /**
   * Retrieves the holiday for employee, but at the same time PREVENTS not authorized access by going back.
   *
   * @param {number} employeeId - The employee serial id.
   * @param {number} holidayId - The holiday serial id.
   * @returns {Promise<Object>} The promised Holiday data.
   */
  async retrieveHoliday(employeeId, holidayId) {
    const res = await axios.get(`api/employees/${employeeId}/holidays/${holidayId}`);
    return res.data;
    // return res.data;
  }

  async createHoliday(formDTO, tz) {
    const dto = prepareHolidayFormForSave(formDTO, tz);
    const res = await axios.post(`api/employees/${dto.employeeId}/holidays`, dto);
    return res.data;
  }

  async updateHoliday(formDTO, tz) {
    const dto = prepareHolidayFormForSave(formDTO, tz);

    const { id } = formDTO;
    const res = await axios.put(`api/employees/${dto.employeeId}/holidays/${id}`, dto);
    return res.data;
  }

  async removeHoliday({ id, employeeId } = {}) {
    const res = await axios.delete(`api/employees/${employeeId}/holidays/${id}`);
    return res.data;
  }

  /**
   * Retrieves the list of employee working hours.
   *
   * @param {number} employeeId - The employee id.
   * @param {Object} options - The read options, additional params...
   * @param {number} options.companyId - The company's serial id - needed to properly build the route.
   * @returns {Promise<[]>} The promise with whs DTO.
   */
  async retrieveWHs(employeeId, { companyId }) {
    const res = await axios.get(`api/companies/${companyId}/employees/${employeeId}/whs`);
    return res.data;
  }

  /**
   * Updates all defined WHs at once.
   *
   * @param {Object} frmWHs - The form DTO object.
   * @param {Object} options - The write options, additional params...
   * @param {number} options.companyId - The company's serial id - needed to properly build the route.
   * @param {number} options.employeeId - The employee's serial id - needed to properly build the route.
   * @returns {Promise<[]>} The promise with whs DTO.
   */
  async updateWHs(frmWHs, { companyId, employeeId }) {
    const whs = frmWHs.map((w) => {
      const { uid, modals, ...persistWHS } = w;
      return persistWHS;
    });
    // adjust form DTO to REST DTO
    const dto = {
      entityId: employeeId,
      whs,
    };
    const res = await axios.put(`api/companies/${companyId}/employees/${employeeId}/whs`, dto);
    return res.data;
  }

  /**
   * Retrieves the list of employee working hours.
   *
   * @param {number} employeeId - The employee id.
   * @param {Object} options - The read options, additional params...
   * @param {number} options.companyId - The company's serial id - needed to properly build the route.
   * @returns {Promise<[]>} The promise with assigned services DTOs.
   */
  async retrieveAssignedServices(employeeId, { companyId }) {
    const res = await axios.get(`api/companies/${companyId}/employees/${employeeId}/services`);
    return res.data;
  }

  /**
   * Updates assigned services at once.
   *
   * @param {Object} frmServices - The form DTO object.
   * @param {Object} options - The write options, additional params...
   * @param {number} options.companyId - The company's serial id - needed to properly build the route.
   * @param {number} options.employeeId - The employee's serial id - needed to properly build the route.
   * @returns {Promise<[]>} The promise with whs DTO.
   */
  async updateServices(frmServices, { companyId, employeeId }) {
    const serviceIds = frmServices.filter((srvc) => srvc.assigned).map((srvc) => srvc.id);
    // adjust form DTO to REST DTO
    const dto = {
      serviceIds,
    };
    const res = await axios.patch(`api/companies/${companyId}/employees/${employeeId}/services`, dto);
    return res.data;
  }

  // ==========================
  // custom employee services
  // ==========================

  /**
   * The resource is using this service to set own qms NA flag. The resource is identified by signed in resource.
   *
   * @param {boolean} qmsNA - The qmsNA flag to set for the resource.
   * @returns {Promise<boolean>} The promise with updated flag.
   */
  async setQmsNA(qmsNA) {
    const resId = this.store.getters.getEmployeeId;
    // adjust form DTO to REST DTO
    const dto = {
      qmsNA,
      socketId: this.appNotificationsService.socketId,
    };
    const res = await axios.post(`api/employees/${resId}/qms-na`, dto);
    return res.data.qmsNA;
  }

  async getMsapiAdminConsentURI(employeeId) {
    const res = await axios.get(`api/employees/${employeeId}/msapi-admin-consent-uri`);
    return res.data.adminConsentURI;
  }
}
