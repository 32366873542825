export default {
  install: (Vue) => {
    /**
     * The vue filter for formatting file size into human readable format
     * todo - v0.1.0 - move to functions
     */
    Vue.filter('fltBytes', (value) => {
      const units = ['b', 'kB', 'MB', 'GB', 'TB'];

      let numOfOperations = 0;
      let val = parseFloat(value);

      if (value === '') {
        return value;
      }

      while (val > 1000) {
        val /= 1000;
        val = parseFloat(val).toFixed(2);
        numOfOperations += 1;
      }

      return `${val} ${units[numOfOperations]}`;
    });
  },
};
